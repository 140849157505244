@import url('https://fonts.googleapis.com/css2?family=Exo:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo&display=swap');





p {

    font-family: 'Arimo', sans-serif;
    color: rgb(65, 106, 89);
}

a {
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5 {
    font-weight: 700;
    font-family: 'Exo', sans-serif;


}

.mbg,
.hfooter {

    background: rgb(65, 106, 89);
    background: linear-gradient(90deg, rgba(65, 106, 89, 1) 0%, rgba(9, 25, 19, 1) 100%);


}

.hfooter {
    margin-top: 75px;
    padding-top: 20px;
}

.subpage,
.subpage2,
.subpage3,
.subpage4,
.subpage5 {

    width: 100%;
    min-height: 140px;
    background-image: url("img/header.jpg");
    background-size: cover;


}

.subpage h3,
.subpage2 h3,
.subpage3 h3,
.subpage4 h3,
.subpage5 h3 {
    color: #cfcece;
    font-size: 1.5rem;
    padding-top: 4%;

}

.tkv {

    font-size: 1.2rem;
    font-weight: 700;
    font-family: 'Exo', Arial, sans-serif;
    color: #406857;

}


.subpage2 {
    background-image: url("img/header2.jpg");

}

.subpage3 {
    background-image: url("img/header3.jpg");

}

.subpage4 {
    background-image: url("img/header4.jpg");
}


.subpage5 {
    background-image: url("img/header5.jpg")
}

.subpage2 h3 {
    color: #406857;
}

.subpage3 h3 {
    color: #fff
}

.borderrad {

    border-top-right-radius: 20px;
}

.menubaslik {
    color: #fff;
    font-family: 'Exo', sans-serif;
    font-size: 20px;
    background: #dad7cd;
    width: 100%;
    display: block;
    line-height: 20px;
    box-sizing: border-box;
    padding: 10px;
    font-weight: 700;
    margin-top: 33px;


}

.gridwrapper {
    margin: 0 auto;
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    height: 30vh;
}



.box {

    background-color: rgb(212, 212, 212);

}

.a {
    grid-column: 1;
    grid-row: 1 /span 2;


}

.b {
    grid-column: 2;
    grid-row: 1;

}


.hfooter p,
.shadowcon p {

    color: #95a8a4;
    font-size: 15px;
    font-family: Arimo, sans-serif;
    line-height: 26px;


}

.navbar-toggler, .navbar-toggler:focus , .navbar-toggler:active{

border: solid 1px  #406857;

}

.navbar-toggler-icon {

color: #406857;

}



.shadowcon {
    box-shadow: 0 4px 11px -5px rgb(0 0 0 / 40%);

    padding: 40px;
    margin-top: 33px;
    max-width: 80%;
    background-image: url('img/world.svg');
    background-position: right;
    background-repeat: no-repeat;


}

.fa-brands,
.fa-solid {
    color: rgb(118, 153, 139);
    transition: all 0.3s ease-in;
}


.fa-brands:hover,
.fa-solid:hover {
    color: rgb(46, 73, 62);

}

.btn-socialicons {
   
    font-size: 22px;
    width: 36px;
    text-align: center;
    display: inline-block;
    line-height: 36px;
    height: 36px;
    overflow: hidden;
   
}

.nav-link {

    text-decoration: none;
    color: #a6b1ac;
    font-family: Exo, Arimo;
}

.nav-link:hover,
nav-link:active {
    text-decoration: none;

    color: #c8d4cf;
}

.carousel-caption {
    left: 16%;
    top: 25%;
    text-align: left;
    color: #fff;

}

.carousel-caption h1{
    font-size: 5vh;
    font-weight: 800;


}
.carousel-caption h3{

    font-size: 2.6vh;
    font-weight: 600;

}

.button-24 {
    background: #ef233c;
    border: 1px solid #ef233c;
    border-radius: 6px;
    box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
    font-size: 16px;
    font-weight: 800;
    line-height: 16px;
    min-height: 30px;
    outline: 0;
    padding: 12px 14px;
    text-align: center;
    text-rendering: geometricprecision;
    text-transform: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
}

.button-24:hover,
.button-24:active {
    background-color: initial;
    background-position: 0 0;
    color: #FF4742;
}

.button-24:active {
    opacity: .5;
}



.logocontainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
   padding-top: 30px;
   cursor: pointer;
   

}


.logocontainerbg h2,
.haber {
    text-align: center;
    color: rgb(65, 106, 89);
    padding-top: 12px;
    font-size: 1.5rem;

}


.newsh3 {
    text-align: left;
    color: rgb(65, 106, 89);
    padding-top: 12px;
    font-size: 1.3rem;

}

.line {
    width: 100%;
    background-color: rgb(155, 211, 187);
    height: 1px;
    margin: 0 auto;

}


.line2 {
    width: 100%;
    background-color: #dad7cd;
    height: 1px;
    margin: 0 auto;

}





.linealt {
    width: 100%;
    background-color: rgb(83, 133, 111);
    height: 1px;
    margin: 0 auto;

}


.logocontainerbg {
    margin-top: 10px;
    width: 100%;
    background: rgb(213, 243, 230);
    background: linear-gradient(180deg, rgba(216, 245, 233, 0.8) 0%, rgba(255, 255, 255, 1) 100%);
    border-bottom: solid 1px rgba(213, 243, 230, 1);
    display: flex;
    align-content: flex-start;


}


.logobox {

    border-radius: 1px;
    transition: all 0.3s ease-in-out;
    border: solid 1px #e4f1ec;
    padding: 12px;
    box-shadow: 1px 1px 1px 1px rgba(184, 184, 184, 0.1);


}

.logobox:hover {

    background-color: rgba(255, 255, 255, 0.8)
}


@media (max-width: 800px) {

    .carousel-caption {
        left: 10%;
        top: 6%;
        text-align: left;
        color: #fff;
    
    }


    .logobox {

       margin-bottom: 20px;
    
    
    }


    .carousel-caption h1{
        font-size: 3vh;
        font-weight: 800;
    
    
    }
    .carousel-caption h3{
    
        font-size: 1.6vh;
        font-weight: 600;
    
    }

    .button-24{

        font-size: 12px;
        font-weight: 500;
        outline: 0;
       
    }
    


}